import * as React from 'react'

import {
  Description,
  ShowChart,
} from '@mui/icons-material'
import { Link, Redirect, Router, useLocation } from '@reach/router'

import { DrawerList, DrawerListItem } from 'shared/components'

import BankAccounts from '../components/views/bank_accounts'
import Documents from '../components/views/documents'
import NotFound from '../components/views/not_found'
import Security from '../components/views/security'
import Signature from '../components/views/signature'
import Support from '../components/views/support'
import Layout from '../layouts/app'

const App = () => {
  const pathname = useLocation().pathname.replace(/\/$/, '')
  const basepath = '/app'

  const isCurrentPath = (href: string): boolean => pathname.startsWith(basepath + '/' + href)

  const listItem = (text: string, href: string, icon: React.ReactNode, color?: string) => (
    <DrawerListItem
      text={text}
      href={href}
      icon={icon}
      color={color}
      selected={isCurrentPath(href)}
      component={Link}
    />
  )

  const drawerContent = (
    <DrawerList>
      {listItem('Documentos', 'documents', <ShowChart />)}
      {listItem('Soporte', 'support', <Description />)}
    </DrawerList>
  )

  return (
    <Router basepath={basepath}>
      <Layout
        path='/'
        drawerContent={drawerContent}
      >
        <Redirect
          noThrow
          from='/'
          to='documents'
        />
        <Documents path='documents' />
        <BankAccounts path='bank' />
        <Signature path='signature' />
        <Support path='support' />
        <Security path='security' />
        <NotFound default />
      </Layout>
    </Router>
  )
}

export default App
